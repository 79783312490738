<template>
  <div>
    <div class="template4_view">
      <div class="template4_view_left">
        <div class="template4_view_left_bg">
          <div
            :class="isSelected=='home'?'template4_view_left_view template4_view_left_view_selected':'template4_view_left_view'"
            @click="isShowWindow=true,isSelected='home'"
            v-bind:style="{background:backgroundColor}"
          >
            <img
              v-if="templateForm.background.type=='img' && templateForm.background.imgUrl"
              :src="fileUrl+templateForm.background.imgUrl"
              style="width:100%;height:100%;position:absolute"
            />
            <div class="template4_view_left_list">
              <!-- 左侧图片列表 -->
              <div class="template4_view_left_list_left">
                <div
                  v-for="(item,index) in cardList"
                  :key="index"
                  :class="isSelected==item.cardAddress?'template4_view_left_list_left_view template4_view_left_view_selected':'template4_view_left_list_left_view'"
                  @click.stop="onHandleCard(item)"
                >
                 <div class="template4_view_left_list_left_view_del" v-if="index !=0 ">
                    <i class="el-icon-close" @click="onDelCard(item,index)"></i>
                  </div>
                  <el-image
                    :src="fileUrl+item.imgList[0]"
                    style=" width:100%; height:100%; background:rgba(235,235,235,0.5);cursor: pointer;border-radius:6px; "
                    fit="cover"
                  ></el-image>
                  <p class="template4_view_left_list_left_view_text_bottom">{{item.textTitle}}</p>
                </div>
                <div
                  class="template4_view_left_list_left_view_add"
                  @click.stop="onAddCard"
                >
                  <i class="el-icon-plus template4_view_left_list_left_view_add_icon"></i>
                </div>
              </div>
              <!-- 右侧轮播图 -->
              <div class="template4_view_left_list_right">
                <el-carousel indicator-position="none" height="420px" style=" border-radius: 6px;">
                  <el-carousel-item v-for="item in cardForm.imgList" :key="item">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <p class="template4_view_left_list_right_text_bottom">{{cardForm.textContent}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 400px;" v-show="!bodySize"></div>
      <div class="template4_view_right" v-show="isShowWindow">
        <div v-if="isSelected=='home'">
          <div class="template4_view_right_btn">
            <div>
              <i
                class="el-icon-arrow-right"
                style="font-weight:bold;cursor:pointer"
                @click="isShowWindow=!isShowWindow"
              ></i>&nbsp;页面设置
            </div>
            <el-button
              type="primary"
              round
              size="small"
              style="width:100px"
              @click="onSubmitCallbackCard"
            >保存</el-button>
          </div>

          <div class="template4_view_right_view">
            <div>
              <el-form ref="form" label-position="top" label-width="80px" size="small">
                <el-form-item label="界面名称">
                  <el-input v-model="templateForm.content.name" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="界面说明">
                  <el-input
                    v-model="templateForm.content.instruction"
                    type="textarea"
                    maxlength="200"
                    show-word-limit
                    :rows="10"
                  ></el-input>
                </el-form-item>
                <p style="border:1px dashed #eee;margin:10px 0"></p>
                <el-form-item label="背景设置">
                  <el-radio-group v-model="templateForm.background.type">
                    <el-radio label="img">图片</el-radio>
                    <el-radio label="color">纯色</el-radio>
                    <el-radio label="gradient">渐变</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="背景图片" v-if="templateForm.background.type=='img'">
                  <el-dropdown placement="top" trigger="click">
                    <div class="template4_view_right_view_logo">
                      <i
                        class="el-icon-plus template4_view_right_view_logo_icon"
                        v-if="!templateForm.background.imgUrl"
                      ></i>
                      <el-image
                        v-else
                        :src="fileUrl+templateForm.background.imgUrl"
                        style="width: 80px; height: 80px;background:rgba(235,235,235,0.5);cursor: pointer;"
                        fit="contain"
                      ></el-image>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="dialogEditBgImgFileUpload=true"
                        style="padding:0"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                      <el-dropdown-item style="padding:0">
                        <el-upload
                          style="text-align:center"
                          :action="actionUrl"
                          accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                          :headers="header"
                          :data="{type:0,category:'APP图标'}"
                          :show-file-list="false"
                          :on-success="handleEditBgImgSuccess"
                          :before-upload="beforeEditBgImgUpload"
                        >本地上传</el-upload>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-form-item>

                <el-form-item label="纯色" v-if="templateForm.background.type=='color'">
                  <div
                    style="border:1px solid #DCDFE6;display:flex;justify-content:space-between;width:150px "
                  >
                    <p>{{templateForm.background.color}}</p>
                    <el-color-picker
                      v-model="templateForm.background.color"
                      @change="backgroundColor = templateForm.background.color;"
                    ></el-color-picker>
                  </div>
                </el-form-item>

                <el-form-item label="渐变" v-if="templateForm.background.type=='gradient'">
                  <el-form-item>
                    <el-row :gutter="10">
                      <el-col
                        :span="6"
                        :offset="0"
                        v-for="gradient in gradientList"
                        :key="gradient.id"
                        style="margin-bottom:10px"
                      >
                        <div
                          :class="templateForm.background.gradientId==gradient.id ?'template4_view_right_gradient_selected  template4_view_right_gradient':'template4_view_right_gradient' "
                          v-bind:style="{background:'linear-gradient('+gradient.type+', '+gradient.color1+','+gradient.color2+')'}"
                          @click="selectedGradient(gradient)"
                        ></div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="template4_view_right_btn">
            <div>
              <i
                class="el-icon-arrow-right"
                style="font-weight:bold;cursor:pointer"
                @click="isShowWindow=!isShowWindow"
              ></i>&nbsp;卡片设置
            </div>
            <el-button
              type="primary"
              round
              size="small"
              style="width:100px"
              @click="onSubmitCallbackCard"
            >保存</el-button>
          </div>

          <div class="template4_view_right_view">
            <div>
              <el-form ref="form" label-position="top" label-width="80px" size="small">
                <el-form-item label="标题">
                  <el-input v-model="cardForm.textTitle" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="内容">
                  <el-input
                    v-model="cardForm.textContent"
                    type="textarea"
                    maxlength="200"
                    show-word-limit
                    :rows="10"
                  ></el-input>
                </el-form-item>
                <p style="border:1px dashed #eee;margin:10px 0"></p>

                <el-form-item label="背景图片" class="card_view_right_img_card">
                  <el-row :gutter="0">
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="24"
                      :xs="24"
                      :offset="0"
                      v-for="(item,index) in cardForm.imgList"
                      :key="index"
                      style="margin-bottom:10px;"
                    >
                      <div class="card_view_right_img">
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="fileUrl+item"
                          fit="contain"
                        ></el-image>
                        <label @click="delEditImgs(index)" class="delImgIcon">
                          <i
                            class="el-icon-delete"
                            style="transform:rotate(-45deg); color:#FFFFFF;"
                          ></i>
                        </label>
                      </div>
                    </el-col>
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="24"
                      :xs="24"
                      :offset="0"
                      style="margin-bottom:10px;"
                      v-if="cardForm.imgList.length<9"
                    >
                      <el-dropdown placement="top">
                        <div class="addImg_card">
                          <i class="el-icon-plus addImg_card_icon"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            @click.native="dialogEditImgFileUpload=true"
                            style="padding:0"
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                          <el-dropdown-item style="padding:0">
                            <el-upload
                              style="text-align:center"
                              :action="actionUrl"
                              accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                              :headers="header"
                              :data="{type:0,category:'图片'}"
                              :show-file-list="false"
                              :on-success="handleEditImgSuccess"
                              :before-upload="beforeEditImgUpload"
                              multiple
                              :limit="isMaxImgCount"
                            >本地上传</el-upload>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                  <span>多个图以轮播形式展示最多9个</span>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 子界面背景图 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditBgImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'背景图片'" v-if="dialogEditBgImgFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditBgImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditBgImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 卡片图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getGradientListApi,
  delTemplateCardApi,
  editTemplateCardApi
} from "../../../api/data";
import Aes from "../../../utils/aes";
import FileUpload from "../../../components/FileUpload";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      bodySize: false, //当前窗口是否小于1024
      isShowWindow: true, //是否显示右侧操作窗口
      isSelected: "home", //选中
      dialogEditBgImgFileUpload: false, //背景图窗口
      dialogEditImgFileUpload: false, //卡片图片
      gradientList: [], //渐变集合
      backgroundColor: "",
      cardList: [],
      cardForm: {},
      addCard: {
        cardType: "ordinary",
        color: "#CD5D5D",
        iconUrl: "/iptv/files/images/2/1650433366798.jpg",
        imgList: [],
        imgUrl: "",
        isdefault: "true",
        qrImg: "",
        qrType: "url",
        qrUrl: "",
        reqImgUrl:"",
        reqQr: "",
        reqQrType: "url",
        reqType: "none",
        reqVideoUrl: "",
        showType: "fullscreen",
        state: "on",
        style: "text",
        tag: "template",
        textContent: "",
        textContent1: "",
        textContent2: "",
        textTitle: "",
        textTitle1: "",
        textTitle2: "",
        title: "123",
        type: "img",
        url: "123",
        videoUrl: ""
      }
    };
  },
  watch: {
    "templateForm.background.type"(newVal) {
      if (newVal == "color") {
        this.backgroundColor = this.templateForm.background.color;
      } else if (newVal == "gradient") {
        this.backgroundColor =
          "linear-gradient(" +
          this.templateForm.background.gradient.type +
          ", " +
          this.templateForm.background.gradient.color1 +
          "," +
          this.templateForm.background.gradient.color2 +
          ")";
      }
    }
  },
  props: {
    templateForm: [],
    isPrese: {
      typeL: Boolean,
      default: false
    }
  },
  computed: {
    //最大上传图片数
    isMaxImgCount() {
      return 9 - this.cardForm.imgList.length;
    },
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    console.log(this.templateForm);
    this.cardList = this.templateForm.card;

    if (this.templateForm.background.type == "color") {
      this.backgroundColor = this.templateForm.background.color;
    } else if (this.templateForm.background.type == "gradient") {
      this.backgroundColor =
        "linear-gradient(" +
        this.templateForm.background.gradient.type +
        ", " +
        this.templateForm.background.gradient.color1 +
        "," +
        this.templateForm.background.gradient.color2 +
        ")";
    }
    this.initGradient();
  },
  methods: {
    //初始化渐变色列表
    initGradient() {
      getGradientListApi().then(res => {
        if (res) this.gradientList = res.data;
      });
    },

    //批量保存
    onSubmitCallbackCard() {
      this.templateForm.content.groupId = this.placesInfo.groupId;
      this.templateForm.background.tag = this.placesInfo.tag;
      // let card = {};
      // for (let i = 0; i < this.cardList.length; i++) {
      //   card[this.cardList[i].cardAddress] = this.cardList[i];
      // }
      editTemplateCardApi({
        background: this.templateForm.background,
        card: this.cardList,
        content: this.templateForm.content,
        isPrese: this.isPrese,
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.$emit("callbackSubmit");
        }
      });
    },

    //新增卡片
    onAddCard() {
      this.cardList.push(this.addCard);
    },
    onDelCard(item,index){
      if(item.isdefault){
        this.cardList.splice(index,1);
        this.$message.success("删除成功");
      }else{
        delTemplateCardApi({id:item.id}).then(res=>{
           this.$message.success(res.msg);
        })
      }
    },
    //删除图片集合
    delEditImgs(index) {
      this.cardForm.imgList.splice(index, 1);
    },

    //选择渐变事件
    selectedGradient(obj) {
      this.templateForm.background.gradient = obj;
      this.backgroundColor =
        "linear-gradient(" +
        obj.type +
        ", " +
        obj.color1 +
        "," +
        obj.color2 +
        ")";
    },

    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },

    //上传子界面背景图完成事件
    handleEditBgImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.templateForm.background.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传子界面背景图前事件
    beforeEditBgImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库子界面背景图添加完成事件
    onSubmitEditBgImg() {
      this.dialogEditBgImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.templateForm.background.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //监听当前窗口大小调整占用右侧DIV
    wathSize() {
      if (document.body.clientWidth <= 1024) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        if (document.body.clientWidth <= 1024) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    },

    //上传子界面背景图完成事件
    handleEditImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传子界面背景图前事件
    beforeEditImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.cardForm.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },

    //选择卡片
    onHandleCard(item) {
      this.isShowWindow = true;
      this.isSelected = item.cardAddress;
      this.cardForm = item;
    }
  }
};
</script>
<style lang='scss'>
.template4_view_left_view_selected {
  outline: 2px dashed #025cdf;
}

.template4_view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.template4_view_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .template4_view_left_bg {
    background: url("../../../assets/hotel_interface.png");
    background-size: cover;
    width: 100%;
    max-width: 810px;
    height: 532px;
    padding-left: 5px;
    padding-top: 4px;
  }
  .template4_view_left_view {
    width: 100%;
    max-width: 802px;
    height: 458px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    .template4_view_left_list {
      position: relative;
      height: 458px;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      .template4_view_left_list_left {
        overflow-y: auto;
        height: 458px;
        width: 30%;
        padding: 20px;
        .template4_view_left_list_left_view {
          .el-image {
            display: flexbox;
          }
          height: 120px;
          width: 200px;
          margin-bottom: 20px;
          position: relative;
          .template4_view_left_list_left_view_text_bottom {
            position: absolute;
            bottom: 0px;
            left: 0;
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
            width: 100%;
            height: 30px;
            line-height: 40px;
            text-align: center;
            z-index: 2;
            border-radius: 0 0 6px 6px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #ffffff;
          }
        }
        .template4_view_left_list_left_view_add {
          height: 120px;
          width: 200px;
          background: rgba(127, 147, 177, 0.2);
           text-align: center;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          .template4_view_left_list_left_view_add_icon{
             font-size: 60px;
          }
        }
         .template4_view_left_list_left_view_del {
              position: absolute;
              top: 2px;
              right: 2px;
              z-index: 4;
            }
            .template4_view_left_list_left_view_del:hover {
              color: #025cdf;
            }
      }
      .template4_view_left_list_left::-webkit-scrollbar {
        width: 0px;
      }
      .template4_view_left_list_left:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.8);
      }
      .template4_view_left_list_left::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 6px;
      }
      .template4_view_left_list_right {
        width: 70%;
        padding: 20px;
        position: relative;
        .template4_view_left_list_right_text_bottom {
          margin: 0 20px;
          padding: 0 10px;
          position: absolute;
          bottom: 18px;
          left: 0;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
          width: 93%;
          // line-height: 40px;
          // text-align: center;
          min-height: 30px;
          z-index: 2;
          border-radius: 0 0 6px 6px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 这里是超出几行省略 */
          overflow: hidden;
          color: #ffffff;
        }
      }
    }
  }
}

.template4_view_right {
  background: #ffffff;
  border-top: 1px solid #d7dae2;
  width: 20%;
  height: 100%;
  z-index: 9;
  min-width: 300px;
  position: absolute;
  top: 0;
  right: 0;

  .template4_view_right_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    border-bottom: 1px solid #d7dae2;
    padding: 10px;
  }
  .template4_view_right_view {
    overflow-y: scroll;
    height: 100%;
    padding: 20px;
    width: 100%;
    .template4_view_right_view_logo {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      width: 80px;
      height: 80px;
    }
    .template4_view_right_view_logo:hover {
      border-color: #5e72f9;
    }
    .template4_view_right_view_logo_icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    // 渐变选中
    .template4_view_right_gradient_selected {
      border: 1px solid #909399;
      box-shadow: 0 2px 12px 0 #619aec8c;
    }
    .template4_view_right_gradient {
      width: 50px;
      height: 50px;
      cursor: pointer;
      border-radius: 4px;
    }
    .template4_view_right_gradient:hover {
      box-shadow: 0 2px 12px 0 #619aec8c;
    }
  }
  .template4_view_right_view::-webkit-scrollbar {
    width: 6px;
  }
  .template4_view_right_view:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .template4_view_right_view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}
</style>