<template>
  <div>
    <div class="template1_view">
      <div class="template1_view_left">
        <div class="template1_view_left_bg">
          <div
            :class="isSelected=='home'?'template1_view_left_view template1_view_left_view_selected':'template1_view_left_view'"
            @click="isShowWindow=true,isSelected='home'"
            v-bind:style="{background:backgroundColor}"
          >
            <img
              v-if="templateForm.background.type=='img' && templateForm.background.imgUrl"
              :src="fileUrl+templateForm.background.imgUrl"
              style="width:100%;height:100%;position:absolute"
            />

            <div class="template1_view_left_list">
              <div
                :class="isSelected=='card1'?'template1_view_left_list_card template1_view_left_view_selected':'template1_view_left_list_card'"
                @click.stop="isShowWindow=true,isSelected='card1',cardForm=card1"
                v-bind:style="{background:card1Background}"
              >
                <!-- 徽标 -->
                <div class="template1_view_left_list_card_badge">1</div>
                <!-- 轮播图 -->
                <el-carousel
                  v-if="card1.type=='img'"
                  indicator-position="none"
                  height="358px"
                  class="template1_view_left_list_card_carousel"
                >
                  <el-carousel-item v-for="item in this.card1.imgList" :key="item">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-- 悬浮文字 -->
                <p
                  v-if="card1.style=='text'"
                  class="template1_view_left_list_card_text_bottom"
                >{{card1.title}}</p>

                <!-- 悬浮图标 -->
                <div v-if="card1.style=='icon'" class="template1_view_left_list_card_text_center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="fileUrl+card1.iconUrl"
                    fit="fill"
                  ></el-image>
                  <p>{{card1.title}}</p>
                </div>
                <!-- 悬浮二维码 -->
                <div v-if="card1.style=='qr'" class="template1_view_left_list_card_text_center">
                  <el-image style="width: 50px; height: 50px" :src="fileUrl+card1.qrImg" fit="fill"></el-image>
                </div>
              </div>
              <div
                :class="isSelected=='card2'?'template1_view_left_list_card template1_view_left_view_selected':'template1_view_left_list_card'"
                @click.stop="isShowWindow=true,isSelected='card2',cardForm=card2"
                v-bind:style="{background:card2Background}"
              >
                <!-- 徽标 -->
                <div class="template1_view_left_list_card_badge">2</div>
                <!-- 轮播图 -->
                <el-carousel
                  v-if="card2.type=='img'"
                  indicator-position="none"
                  height="358px"
                  class="template1_view_left_list_card_carousel"
                >
                  <el-carousel-item v-for="item in this.card2.imgList" :key="item">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-- 悬浮文字 -->
                <p
                  v-if="card2.style=='text'"
                  class="template1_view_left_list_card_text_bottom"
                >{{card2.title}}</p>

                <!-- 悬浮图标 -->
                <div v-if="card2.style=='icon'" class="template1_view_left_list_card_text_center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="fileUrl+card2.iconUrl"
                    fit="fill"
                  ></el-image>
                  <p>{{card2.title}}</p>
                </div>
                <!-- 悬浮二维码 -->
                <div v-if="card2.style=='qr'" class="template1_view_left_list_card_text_center">
                  <el-image style="width: 50px; height: 50px" :src="fileUrl+card2.qrImg" fit="fill"></el-image>
                </div>
              </div>
              <div
                :class="isSelected=='card3'?'template1_view_left_list_card template1_view_left_view_selected':'template1_view_left_list_card'"
                @click.stop="isShowWindow=true,isSelected='card3',cardForm=card3"
                v-bind:style="{background:card3Background}"
              >
                <!-- 徽标 -->
                <div class="template1_view_left_list_card_badge">3</div>
                <!-- 轮播图 -->
                <el-carousel
                  v-if="card3.type=='img'"
                  indicator-position="none"
                  height="358px"
                  class="template1_view_left_list_card_carousel"
                >
                  <el-carousel-item v-for="item in this.card3.imgList" :key="item">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-- 悬浮文字 -->
                <p
                  v-if="card3.style=='text'"
                  class="template1_view_left_list_card_text_bottom"
                >{{card3.title}}</p>

                <!-- 悬浮图标 -->
                <div v-if="card3.style=='icon'" class="template1_view_left_list_card_text_center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="fileUrl+card3.iconUrl"
                    fit="fill"
                  ></el-image>
                  <p>{{card3.title}}</p>
                </div>
                <!-- 悬浮二维码 -->
                <div v-if="card3.style=='qr'" class="template1_view_left_list_card_text_center">
                  <el-image style="width: 50px; height: 50px" :src="fileUrl+card3.qrImg" fit="fill"></el-image>
                </div>
              </div>
              <div
                :class="isSelected=='card4'?'template1_view_left_list_card template1_view_left_view_selected':'template1_view_left_list_card'"
                @click.stop="isShowWindow=true,isSelected='card4',cardForm=card4"
                v-bind:style="{background:card4Background}"
              >
                <!-- 徽标 -->
                <div class="template1_view_left_list_card_badge">3</div>
                <!-- 轮播图 -->
                <el-carousel
                  v-if="card4.type=='img'"
                  indicator-position="none"
                  height="358px"
                  class="template1_view_left_list_card_carousel"
                >
                  <el-carousel-item v-for="item in this.card4.imgList" :key="item">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-- 悬浮文字 -->
                <p
                  v-if="card4.style=='text'"
                  class="template1_view_left_list_card_text_bottom"
                >{{card4.title}}</p>

                <!-- 悬浮图标 -->
                <div v-if="card4.style=='icon'" class="template1_view_left_list_card_text_center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="fileUrl+card4.iconUrl"
                    fit="fill"
                  ></el-image>
                  <p>{{card4.title}}</p>
                </div>
                <!-- 悬浮二维码 -->
                <div v-if="card4.style=='qr'" class="template1_view_left_list_card_text_center">
                  <el-image style="width: 50px; height: 50px" :src="fileUrl+card4.qrImg" fit="fill"></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 400px;" v-show="!bodySize"></div>
      <div class="template1_view_right" v-show="isShowWindow">
        <div v-if="isSelected=='home'">
          <div class="template1_view_right_btn">
            <div>
              <i
                class="el-icon-arrow-right"
                style="font-weight:bold;cursor:pointer"
                @click="isShowWindow=!isShowWindow"
              ></i>&nbsp;页面设置
            </div>
            <el-button
              type="primary"
              round
              size="small"
              style="width:100px"
              @click="onSubmitCallbackCard"
            >保存</el-button>
          </div>

          <div class="template1_view_right_view">
            <div>
              <el-form ref="form" label-position="top" label-width="80px" size="small">
                <el-form-item label="界面名称">
                  <el-input v-model="templateForm.content.name" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="界面说明">
                  <el-input
                    v-model="templateForm.content.instruction"
                    type="textarea"
                    maxlength="200"
                    show-word-limit
                    :rows="10"
                  ></el-input>
                </el-form-item>
                <p style="border:1px dashed #eee;margin:10px 0"></p>
                <el-form-item label="背景设置">
                  <el-radio-group v-model="templateForm.background.type">
                    <el-radio label="img">图片</el-radio>
                    <el-radio label="color">纯色</el-radio>
                    <el-radio label="gradient">渐变</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="背景图片" v-if="templateForm.background.type=='img'">
                  <el-dropdown placement="top" trigger="click">
                    <div class="template1_view_right_view_logo">
                      <i
                        class="el-icon-plus template1_view_right_view_logo_icon"
                        v-if="!templateForm.background.imgUrl"
                      ></i>
                      <el-image
                        v-else
                        :src="fileUrl+templateForm.background.imgUrl"
                        style="width: 80px; height: 80px;background:rgba(235,235,235,0.5);cursor: pointer;"
                        fit="contain"
                      ></el-image>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="dialogEditBgImgFileUpload=true"
                        style="padding:0"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                      <el-dropdown-item style="padding:0">
                        <el-upload
                          style="text-align:center"
                          :action="actionUrl"
                          accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                          :headers="header"
                          :data="{type:0,category:'APP图标'}"
                          :show-file-list="false"
                          :on-success="handleEditBgImgSuccess"
                          :before-upload="beforeEditBgImgUpload"
                        >本地上传</el-upload>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-form-item>

                <el-form-item label="纯色" v-if="templateForm.background.type=='color'">
                  <div
                    style="border:1px solid #DCDFE6;display:flex;justify-content:space-between;width:150px "
                  >
                    <p>{{templateForm.background.color}}</p>
                    <el-color-picker
                      v-model="templateForm.background.color"
                      @change="backgroundColor = templateForm.background.color;"
                    ></el-color-picker>
                  </div>
                </el-form-item>

                <el-form-item label="渐变" v-if="templateForm.background.type=='gradient'">
                  <el-form-item>
                    <el-row :gutter="10">
                      <el-col
                        :span="6"
                        :offset="0"
                        v-for="gradient in gradientList"
                        :key="gradient.id"
                        style="margin-bottom:10px"
                      >
                        <div
                          :class="templateForm.background.gradientId==gradient.id ?'template1_view_right_gradient_selected  template1_view_right_gradient':'template1_view_right_gradient' "
                          v-bind:style="{background:'linear-gradient('+gradient.type+', '+gradient.color1+','+gradient.color2+')'}"
                          @click="selectedGradient(gradient)"
                        ></div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div v-else>
          <InterfaceCard
            :cardForm="cardForm"
            v-on:callbackIsShowWindow="onSubmitCallbackIsShowWindow"
            v-on:callbackGradient="onSubmitCallbackGradient"
            v-on:callbackColor="onSubmitCallBackColor"
            v-on:callbackCard="onSubmitCallbackCard"
          ></InterfaceCard>
        </div>
      </div>
    </div>

    <!-- 子界面背景图 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditBgImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'背景图片'" v-if="dialogEditBgImgFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditBgImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditBgImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getGradientListApi, editMainInterfaceApi } from "../../../api/data";
import Aes from "../../../utils/aes";
import FileUpload from "../../../components/FileUpload";
import InterfaceCard from "../../../components/InterfaceCard";
export default {
  components: {
    FileUpload,
    InterfaceCard
  },
  data() {
    return {
      bodySize: false, //当前窗口是否小于1024
      isShowWindow: true, //是否显示右侧操作窗口
      isSelected: "home", //选中
      dialogEditBgImgFileUpload: false, //背景图窗口
      gradientList: [], //渐变集合
      card1: {},
      card2: {},
      card3: {},
      card4: {},
      cardForm: {},
      backgroundColor: "",
      card1Background: "",
      card2Background: "",
      card3Background: "",
      card4Background: ""
    };
  },
  watch: {
    "templateForm.background.type"(newVal) {
      if (newVal == "color") {
        this.backgroundColor = this.templateForm.background.color;
      } else if (newVal == "gradient") {
        this.backgroundColor =
          "linear-gradient(" +
          this.templateForm.background.gradient.type +
          ", " +
          this.templateForm.background.gradient.color1 +
          "," +
          this.templateForm.background.gradient.color2 +
          ")";
      }
    },
    "card1.type"(newVal) {
      if (newVal == "color") {
        this.card1Background = this.card1.color;
      } else if (newVal == "gradient") {
        this.card1Background =
          "linear-gradient(" +
          this.card1.gradient.type +
          ", " +
          this.card1.gradient.color1 +
          "," +
          this.card1.gradient.color2 +
          ")";
      }
    },
    "card2.type"(newVal) {
      if (newVal == "color") {
        this.card2Background = this.card2.color;
      } else if (newVal == "gradient") {
        this.card2Background =
          "linear-gradient(" +
          this.card2.gradient.type +
          ", " +
          this.card2.gradient.color1 +
          "," +
          this.card2.gradient.color2 +
          ")";
      }
    },
    "card3.type"(newVal) {
      if (newVal == "color") {
        this.card3Background = this.card3.color;
      } else if (newVal == "gradient") {
        this.card3Background =
          "linear-gradient(" +
          this.card3.gradient.type +
          ", " +
          this.card3.gradient.color1 +
          "," +
          this.card3.gradient.color2 +
          ")";
      }
    },
    "card4.type"(newVal) {
      if (newVal == "color") {
        this.card4Background = this.card4.color;
      } else if (newVal == "gradient") {
        this.card4Background =
          "linear-gradient(" +
          this.card4.gradient.type +
          ", " +
          this.card4.gradient.color1 +
          "," +
          this.card4.gradient.color2 +
          ")";
      }
    }
  },
  props: {
    templateForm: [],
    isPrese: {
      typeL: Boolean,
      default: false
    }
  },
  computed: {
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    if (this.templateForm.card.length == 4) {
      this.card1 = this.templateForm.card[0];
      this.card2 = this.templateForm.card[1];
      this.card3 = this.templateForm.card[2];
      this.card4 = this.templateForm.card[3];
    }
    if (this.templateForm.background.type == "color") {
      this.backgroundColor = this.templateForm.background.color;
    } else if (this.templateForm.background.type == "gradient") {
      this.backgroundColor =
        "linear-gradient(" +
        this.templateForm.background.gradient.type +
        ", " +
        this.templateForm.background.gradient.color1 +
        "," +
        this.templateForm.background.gradient.color2 +
        ")";
    }
    this.initGradient();
  },
  methods: {
    //初始化渐变色列表
    initGradient() {
      getGradientListApi().then(res => {
        if (res) this.gradientList = res.data;
      });
    },
    // 子页面返回是否折叠窗口
    onSubmitCallbackIsShowWindow(isShowWindow) {
      this.isShowWindow = isShowWindow;
    },
    //返回渐变
    onSubmitCallbackGradient(obj) {
      if (this.isSelected == "card1") {
        this.card1Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card2") {
        this.card2Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card3") {
        this.card3Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card4") {
        this.card4Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      }
    },
    onSubmitCallBackColor() {
      if (this.isSelected == "card1") {
        this.card1Background = this.card1.color;
      } else if (this.isSelected == "card2") {
        this.card2Background = this.card2.color;
      } else if (this.isSelected == "card3") {
        this.card3Background = this.card3.color;
      } else if (this.isSelected == "card4") {
        this.card4Background = this.card4.color;
      }
    },
    //批量保存
    onSubmitCallbackCard() {
      this.templateForm.background.tag = this.placesInfo.tag;
      this.templateForm.content.groupId = this.placesInfo.groupId;
      editMainInterfaceApi({
        background: this.templateForm.background,
        card: {
          card1: this.card1,
          card2: this.card2,
          card3: this.card3,
          card4: this.card4
        },
        content: this.templateForm.content,
        isPrese: this.isPrese,
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.$emit("callbackSubmit");
        }
      });
    },

    //选择渐变事件
    selectedGradient(obj) {
      this.templateForm.background.gradient = obj;
      this.backgroundColor =
        "linear-gradient(" +
        obj.type +
        ", " +
        obj.color1 +
        "," +
        obj.color2 +
        ")";
    },

    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },

    //上传子界面背景图完成事件
    handleEditBgImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.templateForm.background.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传子界面背景图前事件
    beforeEditBgImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库子界面背景图添加完成事件
    onSubmitEditBgImg() {
      this.dialogEditBgImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.templateForm.background.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //监听当前窗口大小调整占用右侧DIV
    wathSize() {
      if (document.body.clientWidth <= 1024) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        if (document.body.clientWidth <= 1024) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    }
  }
};
</script>
<style lang='scss'>
.template1_view_left_view_selected {
  outline: 2px dashed #025cdf;
}

.template1_view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.template1_view_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .template1_view_left_bg {
    background: url("../../../assets/hotel_interface.png");
    background-size: cover;
    width: 100%;
    max-width: 810px;
    height: 532px;
    padding-left: 5px;
    padding-top: 4px;
  }
  .template1_view_left_view {
    width: 100%;
    max-width: 802px;
    height: 458px;
    // border: 2px solid red;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    .template1_view_left_list {
      position: relative;
      padding: 50px 20px;
      height: 458px;
      display: flex;
      .template1_view_left_list_card {
        width: 200px;
        height: 100%;
        margin: 0 10px;
        border: 1px solid #ebeef5;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        color: #ffffff;
        .template1_view_left_list_card_badge {
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          background: rgba($color: #ff0000, $alpha: 1);
          border-radius: 20px;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .template1_view_left_list_card_carousel {
          border-radius: 6px;
        }
        .template1_view_left_list_card_text_center {
          position: absolute;
          top: 40%;
          left: 20%;
          width: 100px;
          text-align: center;
          z-index: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .template1_view_left_list_card_text_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
          width: 100%;
          height: 30px;
          line-height: 40px;
          text-align: center;
          z-index: 2;
          border-radius: 0 0 6px 6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #ffffff;
        }
      }
    }
  }
}

.template1_view_right {
  background: #ffffff;
  border-top: 1px solid #d7dae2;
  width: 20%;
  height: 100%;
  z-index: 9;
  min-width: 300px;
  position: absolute;
  top: 0;
  right: 0;

  .template1_view_right_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    border-bottom: 1px solid #d7dae2;
    padding: 10px;
  }
  .template1_view_right_view {
    overflow-y: scroll;
    height: 100%;
    padding: 20px;
    width: 100%;
    .template1_view_right_view_logo {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      width: 80px;
      height: 80px;
    }
    .template1_view_right_view_logo:hover {
      border-color: #5e72f9;
    }
    .template1_view_right_view_logo_icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    // 渐变选中
    .template1_view_right_gradient_selected {
      border: 1px solid #909399;
      box-shadow: 0 2px 12px 0 #619aec8c;
    }
    .template1_view_right_gradient {
      width: 50px;
      height: 50px;
      cursor: pointer;
      border-radius: 4px;
    }
    .template1_view_right_gradient:hover {
      box-shadow: 0 2px 12px 0 #619aec8c;
    }
  }
  .template1_view_right_view::-webkit-scrollbar {
    width: 6px;
  }
  .template1_view_right_view:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .template1_view_right_view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}
</style>