<template>
  <div v-loading="loading">
    <el-card shadow="never" v-if="template_selected==''">
      <div slot="header">
        <span class="iconfont icon-SubInterface">&nbsp;子界面</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddTemplate=true">新增页面</el-button>
        </div>
      </div>
      <el-table
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%"
        size="mini"
      >
        <el-table-column prop="name" label="界面名称">
          <template slot-scope="scope">
            {{scope.row.name}}&nbsp;&nbsp;
            <el-tag type="success" size="mini" v-if="scope.row.isdefault=='true'">默认</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="instruction" label="界面说明"></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.isdefault=='true'">
              <el-button type="text" size="small">预览</el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.name=='周边景点' || scope.row.name=='线上商城' || scope.row.name=='电视直播'"
                @click="toLinkUrl(scope.row.name)"
              >编辑</el-button>
              <el-button type="text" size="small" v-else>小程序</el-button>
            </div>
            <div v-else>
              <el-button type="text" size="small" @click="editSubInterface(scope.row)">编辑</el-button>

              <el-button type="text" size="small" @click="delContentPage(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 添加页面模板 -->
    <div v-else>
      <div
        @click="template_selected=''"
        style="position: absolute;top: 30px;z-index: 99; cursor:pointer;"
      >
        <i class="el-icon-back" style="font-size:16px">&nbsp;&nbsp;返回</i>
      </div>
      <Template1
        :templateForm="templateForm"
        v-if="template_selected=='template1'"
        v-on:callbackSubmit="initLoad"
      ></Template1>
      <Template2
        :templateForm="templateForm"
        v-if="template_selected=='template2'"
        v-on:callbackSubmit="initLoad"
      ></Template2>
      <Template3
        :templateForm="templateForm"
        v-if="template_selected=='template3'"
        v-on:callbackSubmit="initLoad"
      ></Template3>
      <Template4
        :templateForm="templateForm"
        v-if="template_selected=='template4'"
        v-on:callbackSubmit="initLoad"
      ></Template4>
    </div>

    <!-- 选择模板 -->
    <el-dialog :visible.sync="dialogAddTemplate" :close-on-click-modal="false" width="900px">
      <span slot="title">选择模板</span>
      <div style="padding:20px 0">
        <div>
          <el-row :gutter="20">
            <el-col
              :span="8"
              :offset="index %2==0?4:0"
              v-for="(template,index) in templateList"
              :key="index"
            >
              <div class="template_body">
                <img :src="template.imgUrl" style="width:100%;height:100%" />
                <div class="template_selected">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="onSelectedTemplate(template.tag,template)"
                  >选择模板</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getContentPageApi,
  getTemplateApi,
  delContentPageApi
} from "../../api/data";
import Aes from "../../utils/aes";
import Template1 from "./template/Template1";
import Template2 from "./template/Template2";
import Template3 from "./template/Template3";
import Template4 from "./template/Template4";
export default {
  components: {
    Template1,
    Template2,
    Template3,
    Template4
  },
  data() {
    return {
      loading: true,
      currentPage: 1, //当前页
      pageSize: 15, //一页显示多少条
      list: [],
      dialogAddTemplate: false, //添加页面模板
      template_selected: "",
      templateList: [],
      templateForm: {}
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  created() {
    this.initLoad();
    this.initTemplate();
  },
  methods: {
    //初始化子界面列表
    initLoad() {
      getContentPageApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.loading = false;
        this.list = res.data;
      });
    },
    //初始化新增模板
    initTemplate() {
      getTemplateApi().then(res => {
        this.templateList = res.data;
      });
    },
    //编辑
    editSubInterface(row) {
      this.template_selected = row.templateTag;
      this.templateForm = row;
    },
    //删除界面
    delContentPage(id) {
      this.$confirm("此操作将永久删除该界面, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delContentPageApi({ id: id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //选择模板
    onSelectedTemplate(val, obj) {
      this.template_selected = val;
      this.templateForm = obj;
      this.dialogAddTemplate = false;
    },
    //跳转可后台编辑的页面
    toLinkUrl(val) {
      if (val == "周边景点") {
        this.$store.commit("setLinkUrl", "/scenery");
      } else if (val == "线上商城") {
        this.$store.commit("setLinkUrl", "/commodity");
      } else if (val == "电视直播") {
        this.$store.commit("setLinkUrl", "/networkSource");
      }
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.template_body {
  position: relative;
  box-sizing: border-box;
  height: 140px;
  width: 240px;
  margin-bottom: 20px;
}
.template_body:hover .template_selected {
  opacity: 1;
}
.template_selected {
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
</style>